import React from 'react';
import { FaWhatsapp, FaLocationArrow, FaEnvelope, FaLinkedin, FaInstagram } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import logo from '../assets/logos2.png';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-8">

      {/* Logo Section */}
      <div className="container mx-auto px-6 md:px-12">
        <div className="flex justify-center md:justify-between items-center pb-6 border-b border-gray-700">
          <Link to="/" className="flex items-center space-x-2">
            <img className="h-12 w-auto" src={logo} alt="Company Logo" />
            <span className="sr-only">PixelLink</span>
          </Link>
        </div>
      </div>

      {/* Footer Content */}
      <div className="container mx-auto px-6 md:px-12 mt-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-16 text-center md:text-left">

          {/* Contact Section */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Contact Us</h3>
            <p className="flex items-center justify-center md:justify-start space-x-2 text-sm">
              <FaEnvelope className="text-indigo-400" />
              <span>contact@pixellinkdigital.com</span>
            </p>
            <p className="flex items-center justify-center md:justify-start space-x-2 text-sm">
              <FaLocationArrow className="text-indigo-400" />
              <span>11609 Myrtle Ave, Richmond Hill, Queens, 11418</span>
            </p>
            <p className="flex items-center justify-center md:justify-start space-x-2 text-sm">
              <FaWhatsapp className="text-green-400" />
              <span>+1 (929) 218-9460</span>
            </p>
          </div>

          {/* Navigation Section */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Quick Links</h3>
            <ul className="space-y-2 text-sm">
              <li><Link to="/about-us" className="hover:text-indigo-400">About</Link></li>
              <li><Link to="/contact" className="hover:text-indigo-400">Contact</Link></li>
            </ul>
          </div>

          {/* Social Media Section */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold">Follow Us</h3>
            <div className="flex justify-center md:justify-start space-x-6">
              <a href="https://www.linkedin.com/company/pixel-link-digital/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn" className="hover:text-indigo-400">
                <FaLinkedin size={24} />
              </a>
              <a href="https://www.instagram.com/pixellinkdigital/" target="_blank" rel="noopener noreferrer" aria-label="Instagram" className="hover:text-indigo-400">
                <FaInstagram size={24} />
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter" className="hover:text-indigo-400">
                <span className="text-sm font-semibold" style={{ fontSize: "1.4em" }}>X</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="mt-8 text-center text-sm text-gray-500">
        &copy; {new Date().getFullYear()} PixelLink. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
